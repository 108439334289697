<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Which of the following are acceptable places to put water bottles during the lab (check 2
        that apply)?
      </p>

      <p v-for="option in optionsShuffled" :key="option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.studentAnswers" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemOleMiss116SSQ0Q6',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      options: [
        {text: 'Inside a zippered backpack', value: 'insideBackpack'},
        {text: 'On the table outside the lab room ', value: 'tableOutsideLab'},
        {text: 'In the side pocket of a backpack', value: 'sidePocket'},
        {text: 'On the lab bench', value: 'onBench'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
